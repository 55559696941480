
.v-tabs--primary {
    .v-tab {
        &:first-child {
            border-radius: 999px 0 0 999px;
        }
        &:last-child {
            border-radius: 0 999px 999px 0;
        }
    }
    
}
